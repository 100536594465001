import React from "react"
//import Img from "gatsby-image"
import External from "../assets/external.svg"

const Project = ({ content, itemIndex }) => {
    const { frontmatter } = content
    const clientText = "Created for " + frontmatter.client + " in " + frontmatter.date

    return (
      <li key={frontmatter.title.replace(/\s/g,'')} className={(itemIndex % 2 === 0) ? "evenitem webproject px-10 md:px-40 py-16" : "odditem webproject px-10 md:px-40 py-16"}>
          <h2 className="title text-2xl md:text-4xl uppercase">
            <a href={frontmatter.linkout} target="_blank" rel="noreferrer" className="transition duration-300 ease-in-out opacity-100 hover:opacity-50">
              {frontmatter.title}
              <External className="inline-block"/>
            </a>
          </h2>
          <h4 className="client text-xs">{clientText}</h4>
          <p className="text-sm py-5">{content.rawMarkdownBody}</p>
          <ul className="technologies w-full ">
            { frontmatter.technologies !== null && frontmatter.technologies.map((technology) => {
              return (
                <li key={frontmatter.title + technology} className="mt-5">{technology}</li>
              )
            }) }
           </ul>
        
      </li>
    )
  }

export default Project