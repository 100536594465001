import React from "react"
import Smile from "../assets/smile.svg"
import Emblem from "../assets/emblem.svg"

class Header extends React.Component {
    componentDidMount() {
        function scrollEvent() {
            let h = document.documentElement, 
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight',
                percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100,
                scrollBox = document.getElementById("currentscroll"),
                emblemElement = document.getElementById("emblemouter")
            scrollBox.style.height = percent+'%';
            emblemElement.style.transform = 'rotate('+percent*3.60+'deg)';
        }
        window.addEventListener( 'scroll', scrollEvent)
        window.addEventListener( 'resize', scrollEvent)
        window.addEventListener( 'load', scrollEvent)

        var scrollBar = document.getElementById("scrollindicator")
        var appPage = document.getElementById("holder")
        scrollBar.onclick = function(e) {
          var percentClick =  (e.offsetY / scrollBar.offsetHeight) * appPage.scrollHeight
          console.log(scrollBar.offsetHeight+ "/"+ e.offsetY + "=" + percentClick)
          window.scrollTo(0, percentClick)
        }
      }
    render() {
        return (
            <div id="sidebar">
                <div id="emblemouter">
                    <div id="emblemholder">
                        <div id="smile">
                            <Smile />
                        </div>
                        <Emblem id="emblem" />
                    </div>
                </div>
                <div id="scrollindicator">
                    <div id="currentscroll"></div>    
                </div>
                <div id="bottomText">
                    <h1>&copy;<br />Tom<br/>Doyle<br/>2020</h1>
                </div>         
            </div>
        )
    }
}

export default Header