module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tom Doyle — Web Developer & Designer","short_name":"Tom Doyle","start_url":"/","background_color":"#211bb1","theme_color":"#211bb1","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"57d88519014aee8dabe628821ec3d939"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
