import React from "react"

import Header from "./header"

const Layout = ({ children }) => {
    return ( 
        <React.Fragment>
            <Header />
            <main className="w-full" id="holder">
                {children}
            </main>
        </React.Fragment>
    )
}

export default Layout

/*             <Header />
*/