import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const Designprojects = () => {
    const data = useStaticQuery(
        graphql`
        {
            design_projects: allMarkdownRemark(filter: {internal: {}, fields: {collection: {eq: "design_projects"}}}, sort: {fields: frontmatter___date, order: DESC}) {
                edges {
                  node {
                    id
                    frontmatter {
                      client
                      role
                      title
                      date(formatString: "YYYY")
                      linkOut
                      featuredImage {
                        childImageSharp {
                          fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                          original {
                            src
                          }
                        }
                      }
                    }
                    rawMarkdownBody
                  }
                }
            }
          }
        `
      )
    return (    
      <React.Fragment>
            <h1 className="text-3xl md:text-5xl">Selected Design Projects</h1>
            <p className="hidden md:block text-xl">(Additional and animated work available upon <a href="#sectionContact" style={{color: "#1236ff"}}>request</a>)</p>
            <div id="designprojectslist" className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 w-full text-center md:text-left mx-auto mt-10">
            {
                data.design_projects.edges.map((item, index) => {
                    let currentProject = item.node
                    let linkOut = ""
                    if (currentProject.frontmatter.linkOut !== null) 
                      linkOut = currentProject.frontmatter.linkOut
                    else 
                      linkOut = currentProject.frontmatter.featuredImage.childImageSharp.original.src
                    return (
                      <div key={item + index}>
                      <a href={linkOut}>
                        <div className="details hidden md:block">
                          <h1 className="text-3xl">{currentProject.frontmatter.title}</h1>
                          <h2 className="text-xl">{currentProject.frontmatter.client}</h2>
                          <h4 className="text-base">{currentProject.frontmatter.date}</h4>
                        </div>
                        { item.node.frontmatter.featuredImage !== null && 
                            <Img fluid={item.node.frontmatter.featuredImage.childImageSharp.fluid} alt={item.node.title} />
                        }   
                      </a>
                    </div>
                      )
                })
            }
        </div>
        </React.Fragment>
    )
}
  
export default Designprojects   