import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import WebProjects from "../components/webprojects"
import DesignProjects from "../components/designprojects"


export default class IndexPage extends React.Component {
  render (){
  return (
    <Layout>
      <SEO title="Web Developer &amp; Designer" />
      <section id="sectionIntro" className="min-h-screen py-40 px-10 md:px-40 w-100">
      <h1 className="text-3xl md:text-5xl leading-loose">Hi, I'm Tom Doyle.<br />
          I'm a Web Developer &amp; Graphic Designer from Los Angeles, CA. <span style={{fontSize: "0.8em", color: "#111196"}}>— For the last 7 years I've studied the art of blending design with code, creating forward thinking and inventive websites, designs, and experiences.</span></h1>
        <h1 className="text-4xl hidden">Hi, I'm Tom Doyle.</h1>
        <p className=" hidden text-xl whitespace-normal box-content">I'm a Web Developer &amp; Graphic Designer from Los Angeles, CA. — For the last 7 years I've studied the art of blending design with code, creating forward thinking and inventive websites, designs, and experiences.</p>
      </section>
      <section id="sectionSkills" className="min-h-screen px-10 md:px-40 py-40 md:py-20 whitespace-normal">
            <h1 className="skillheading text-xl">
                My main area of expertise is Front-End Development, creating the client-facing aspects of websites and interfaces.  
            </h1>
            <ul className="mt-8">
                <li className="listheading">
                    <h1 className="text-xl">
                        Languages
                    </h1>
                    <div className="bar"></div>
                </li>
                <li>HTML <span>(Boostrap, E-Mail)</span></li>
                <li>CSS <span>(SASS, PostCSS, Bulma, Tailwind)</span></li>
                <li>JavaScript <span>(ES6, Gatsby, React, jQuery)</span></li>
                <li>Ruby <span>(Rails, Sintatra)</span></li>
                <li>PHP <span>(WordPress, CakePHP)</span></li>
            </ul>
            <ul className="mt-8">
                <li className="listheading">
                    <h1 className="text-xl">
                        Skills
                    </h1>
                    <div className="bar"></div>
                </li>
                <li>Front End Development</li>
                <li>Web Design</li>
                <li>Graphic Design</li>
                <li>Problem Solving</li>
                <li>Plugin and Theme Development</li>
                <li>Bug Fixing</li>
                <li>Responsive Design</li>
                <li>Mockup → HTML Translation</li>
                <li>Merchandise Design</li>
            </ul>
            <ul style={{ paddingBottom: "150px"}}  className="mt-8">
                <li className="listheading">
                    <h1 className="text-xl">
                        Software
                    </h1>
                    <div className="bar"></div>
                </li>
                <li>Figma</li>
                <li>Illustrator</li>
                <li>Sketch</li>
                <li>Photoshop</li>
                <li>After Effects</li>
                <li>InDesign</li>
                <li>VS Code</li>
                <li>Git</li>
            </ul>
      </section>
      <section id="sectionWeb" className="min-h-screen w-full max-w-full py-40 whitespace-normal">
        <WebProjects />
      </section>       
      <section id="sectionDesign" className="min-h-screen px-10 md:px-40 py-40 md:py-20 whitespace-normal">
        <DesignProjects />       
      </section>
      <section id="sectionContact" className="min-h-screen max-w-full py-40 whitespace-normal">
        <p className="px-10 md:px-40 w-90 text-3xl md:text-4xl leading-loose">I'm always interested in new projects, if you are interested in working together you can reach me via email at me[@]tdoyle.me. Thanks for visiting! </p>
      </section>         
    </Layout>
  )}
}
