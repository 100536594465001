/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

//import "./src/styles/reset.css"
//import "./src/styles/stylesheet.css"
//import "./src/styles/media.css"

import "fontsource-poppins/500.css"
import "./src/styles/style.css"
